import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";

/**
 * Renders an error page if no route is found.
 */
function Error() {
    return (
        <Fragment>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" textAlign='center'>
                <img style={{marginTop: "1rem"}} src={process.env.PUBLIC_URL + "/Fujitsu-red.png"} alt='Fujitsu Logo' width={150} height={70} />
                <Typography variant="h1" sx={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}>404 ERROR</Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}>This page doesn't exist</Typography>

            </Grid>
        </Fragment>
    );
};

export default Error;