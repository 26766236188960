import React, { Fragment, useState } from 'react';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { Button, Grid, Typography } from '@mui/material';
import EmployeeTable from '../components/EmployeeTable';

export default function GenerateEmployees() {

    const [employees, setEmployees] = useState([["Not yet available."]]);
    const [location, setLocation] = useState(undefined);

    const handleButtonClick = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/employees`).then(response => {
            console.log(response);
            setLocation(response.headers.location)

            var lowerCased = response.data.map(function (item) {
                var mapped = {};
                for (var key in item) {
                    mapped[key.toLowerCase()] = item[key];
                }
                return mapped;
            });
            var sortedArr = lowerCased.sort((curr, prev) => Number(curr.employee_id) - Number(prev.employee_id));
            console.log(sortedArr);
            setEmployees(sortedArr)
        }).catch(error => {
            console.log(error);
        }
        );
    };

    return (
        <Fragment>
            <Grid container
                spacing={0}
                display={"flex"}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                textAlign={"center"}
            >
                <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}>Oracle / Postgres Generate Employees</Typography>
                <Button variant="contained" sx={{ m: 1, right: "12rem" }} onClick={handleButtonClick}>Generate Employees list</Button>
                <Paper>
                    <EmployeeTable employees={employees} />
                </Paper>
                {location &&
                    <p style={{ fontSize: "10px" }}>Data retrieved from: {location}</p>
                }
            </Grid>
        </Fragment>
    );
}