import { Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";

import SummarizeIcon from '@mui/icons-material/Summarize';
import { Fragment } from "react";
import { Link } from "react-router-dom";

/**
 * This is the Component renders two buttons to navigate to the different pages.
 */
function Dashboard() {
    return (
        <Fragment>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" textAlign='center'>
                <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}>Dashboard</Typography>
                <div style={{ display: "flex" }}>
                    <Card sx={{ maxWidth: 600, minHeight: 140, margin: "0.5rem" }}>
                        <CardActionArea component={Link} to={"/generate-employees-mongodb"} sx={{ m: 1, minWidth: 295, maxWidth: 295  }} >
                            <SummarizeIcon sx={{ fontSize: 200 }} />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    List Employees MongoDB - System of Engagement
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card sx={{ maxWidth: 600, minHeight: 140, margin: "0.5rem" }}>
                        <CardActionArea component={Link} to={"/generate-employees"} sx={{ m: 1, minWidth: 295, maxWidth: 295 }} >
                            <SummarizeIcon sx={{ fontSize: 200 }} />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    List Employees Oracle / Postgres - System of Record
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </Grid>
        </Fragment>
    );
};

export default Dashboard;
