import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    {
        id: 'id',
        label: 'ID',
    },
    {
        id: 'first_name',
        label: 'First Name',
        align: 'center',
    },
    {
        id: 'last_name',
        label: 'Last Name',
        align: 'center',
    },
];

export default function EmployeeTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Fragment>
            <TableContainer sx={{ maxHeight: 700, minWidth: 500 }}>
                <Table stickyHeader aria-label="Employee Table" sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.employees
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((employee, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{employee.employee_id}</TableCell>
                                        <TableCell align="center">{employee.first_name}</TableCell>
                                        <TableCell align="center">{employee.last_name}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.employees.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Fragment>
    );
}