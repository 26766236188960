import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, FormControl, Modal, TextField, } from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { Box } from "@mui/system";
import AlertComponent from "./AlertComponent";
import moment from "moment";

export default function AddUser(props) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'white',
        border: '2px solid #000',
        p: 4,
    };
    const boxStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 2fr)',
        gridTemplateRows: 'auto auto',
        gap: 2,
        gridTemplateAreas: `"id manager_id"
        "first_name title"
        "last_name hire_date"
        "email ."
        "phone button"`,
    }
    const todaysDate = moment().format("DD-MMM-YY");
    console.log(todaysDate)
    const [pickedDate, setPickedDate] = useState(new Date());
    const [userInfo, setUserInfo] = useState({ employeeID: 0, firstName: "", lastName: "", email: "", phone: "", hireDate: todaysDate, managerID: 0, jobTitle: "" });
    const [error, setError] = useState({ message: "", severity: "" });
    const [errorOpen, setErrorOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const handleChange = event => {
        setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
    }

    const handleSubmit = event => {
        event.preventDefault()
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/post-employee`, userInfo).then(response => {
            setSuccessOpen(true);
            setTimeout(function(){
                window.location.reload();
             }, 5000);
        }).catch(error => {
            console.log(error)
            setErrorOpen(true)
        });
    }
    const handleClose = () => {
        setOpen(false);
    }
    useEffect(() => {
        if (errorOpen) {
            setError({ message: "Error: Please check the Employee ID or contact an Admin.", severity: "error" })
            setOpen(true);
        }
        if(successOpen){
            setError({ message: "Success: Employee added successfully.", severity: "success" })
            setOpen(true);
        }
        if(!open){
            setError({ message: "", severity: "" })
        }
    }, [errorOpen, successOpen, open])

    const handleDateChange = (e) => {
        console.log(e)
        setPickedDate(e);
        const mommentObj = moment(e)
        const formattedDate = mommentObj.format("DD-MMM-YY")
        console.log(formattedDate)
        setUserInfo({ ...userInfo, hireDate: formattedDate })
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit}>
                    <FormControl required sx={style} >
                        <Box sx={boxStyle}>
                            <Box sx={{ gridArea: 'id' }}>
                                <TextField sx={{ m: 1 }} required name="employeeID" label="Employee ID" variant="outlined" onChange={handleChange} />
                            </Box>
                            <Box sx={{ gridArea: 'first_name' }}>
                                <TextField required sx={{ m: 1 }} name="firstName" label="First Name" variant="outlined" onChange={handleChange} />
                            </Box>
                            <Box sx={{ gridArea: 'last_name' }}>
                                <TextField required sx={{ m: 1 }} name="lastName" label="Last Name" variant="outlined" onChange={handleChange} />
                            </Box>
                            <Box sx={{ gridArea: 'email' }}>
                                <TextField required sx={{ m: 1 }} name="email" label="Email" variant="outlined" onChange={handleChange} />
                            </Box>
                            <Box sx={{ gridArea: 'phone' }}>
                                <TextField required sx={{ m: 1 }} name="phone" label="Phone" variant="outlined" onChange={handleChange} />
                            </Box>
                            <Box sx={{ gridArea: 'manager_id' }}>
                                <TextField required sx={{ m: 1 }} name="managerID" label="Manager ID" variant="outlined" onChange={handleChange} />
                            </Box>
                            <Box sx={{ gridArea: 'title' }}>
                                <TextField required sx={{ m: 1 }} name="jobTitle" label="Job Title" variant="outlined" onChange={handleChange} />
                            </Box>
                            <Box sx={{ gridArea: 'hire_date' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Hire Date"
                                        required
                                        inputFormat="MMM/dd/yyyy"
                                        value={pickedDate}
                                        onChange={(e) => handleDateChange(e)}
                                        renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Button sx={{ m: 1 }} variant="contained" type="submit" >Submit</Button>
                    </FormControl>
                </form>
            </Modal>
            <AlertComponent message={error.message} severity={error.severity} handleClose={handleClose} open={open} />
        </div>
    );
}

