import React from "react";
//import axios from "axios";
import { Button, FormControl, Modal, TextField, } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function DeleteUser(props) {
    const location = useLocation();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'white',
        border: '2px solid #000',
        p: 4,
    };
    
    const [deleteInfo, setDeleteInfo] = React.useState(0)

    const handleChange = event => {
        setDeleteInfo(event.target.value)
    };

    const handleSubmit = event => {
        event.preventDefault()
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/delete-employee/${deleteInfo}`).then(response => {
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
        });
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <FormControl sx={style}>
                    <TextField sx={{ m: 1 }} label="Employee ID" variant="outlined" onChange={handleChange} />
                    <Button sx={{ m: 1 }} variant="contained" onClick={handleSubmit}>Submit</Button>
                </FormControl>
            </Modal>

        </div >
    );
}

