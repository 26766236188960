import React from 'react';
import Header from './components/Header';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from "react-router-dom";
import Dashboard from './pages/Dashboard.js';
import GenerateEmployees from './pages/GenerateEmployees';
import Error from './pages/Error';
import GenerateEmployeesMongoDB from './pages/GenerateEmployeesMongoDB';

const theme = createTheme({
  palette: {
    primary: {
      main: '#AC2330',
    },
    background: {
      default: "#F3F3F3",
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFFFFF"
        }
      }
    }
  }
});

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Header />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<Error />} />
          <Route path="/generate-employees" element={<GenerateEmployees />} />
          <Route path="/generate-employees-mongodb" element={<GenerateEmployeesMongoDB />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
