import React from "react";
import axios from "axios";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function StressTest(props) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        border: '2px solid #000',
        p: 4,
        textAlign: 'center',
    };

    const handleLowMemClick = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/lowmemorytest`).then(response => {
            console.log(response)
        })
            .catch(error => {
                console.log(error)
            });
    };

    const handleMedMemClick = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/medmemorytest`).then(response => {
            console.log(response)
        })
            .catch(error => {
                console.log(error)
            });
    };

    const handleHighMemClick = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/highmemorytest`).then(response => {
            console.log(response)
        })
            .catch(error => {
                console.log(error)
            });
    };

    const handleCPU = event => {
        event.preventDefault()
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/blockcpu`).then(response => {
            console.log(response)
        })
            .catch(error => {
                console.log(error)
        });
      }

    const handleDiskUsage = event => {
        event.preventDefault()
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/diskusage`).then(response => {
            console.log(response)
        })
            .catch(error => {
                console.log(error)
        });
      }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography>CPU</Typography>
                    <Button sx={{ m: 1, backgroundColor: 'error.main' }} variant="contained" onClick={handleCPU}>Block CPU</Button>

                    <Typography>Memory</Typography>
                    <Button sx={{ m: 1, backgroundColor: 'success.main' }} variant="contained" onClick={handleLowMemClick} >Low</Button>
                    <Button sx={{ m: 1, backgroundColor: 'warning.main' }} variant="contained" onClick={handleMedMemClick}>Medium </Button>
                    <Button sx={{ m: 1, backgroundColor: 'error.main' }} variant="contained" onClick={handleHighMemClick}>High</Button>

                    <Typography>Disk usage</Typography>
                    <Button sx={{ m: 1, backgroundColor: 'error.main' }} variant="contained" onClick={handleDiskUsage}>Disk Usage</Button>
                </Box>
            </Modal>

        </div>
    );
}

