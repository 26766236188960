import React from "react";
import { Snackbar, Alert } from "@mui/material";

function AlertComponent(props) {
  const { open, handleClose, message, severity } = props;
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertComponent;