import React from 'react';
import { Box, Toolbar, IconButton, Drawer, Divider, Typography, ListItemButton, ListItem } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import StressTest from './StressTest';
import AddUser from './AddUser';
import DeleteUser from './DeleteUser';
import { useLocation } from 'react-router-dom';

const drawerWidth = 230;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center'
}));


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

/**
 * This is the Component is used to display the Header and the Drawer on all pages
 */
export default function Header() {
    const location = useLocation()
    
    const [open, setOpen] = useState(true);

    // handles the drawer open and close
    const handleMenuOpen = () => {
        setOpen(!open);
    };
    const [openMemory, setOpenMemory] = React.useState(false);
    const handleOpenMemory = () => setOpenMemory(true);
    const handleCloseMemory = () => setOpenMemory(false);

    const [openAddUser, setOpenAddUser] = React.useState(false);
    const handleOpenAddUser = () => setOpenAddUser(true);
    const handleCloseAddUser = () => setOpenAddUser(false);

    const [openDeleteUser, setOpenDeleteUser] = React.useState(false);
    const handleOpenDeleteUser = () => setOpenDeleteUser(true);
    const handleCloseDeleteUser = () => setOpenDeleteUser(false);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <StressTest open={openMemory} handleClose={handleCloseMemory} />
            <AddUser open={openAddUser} handleClose={handleCloseAddUser} />
            <DeleteUser open={openDeleteUser} handleClose={handleCloseDeleteUser} />

            <AppBar
                position="static"
                sx={{ background: "#FFFFF", borderTop: "3px solid #ac2330" }}
                open={open}
            >
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="primary"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleMenuOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{ marginLeft: "auto" }}>
                        <Link to={"/"}>
                            <img src={process.env.PUBLIC_URL + "/Fujitsu-red.png"} alt='Fujitsu Logo' width={150} height={70} />
                        </Link>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box'
                },
            }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{ height: '5rem' }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}>ToolBox</Typography>
                </DrawerHeader>
                <Divider />
                {location.pathname === "/generate-employees" ?
                <List>
                    <ListItem button component={Link} to="/" >
                        <ListItemText primary="Home" primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold' }} />
                    </ListItem>
                    <ListItemButton onClick={handleOpenMemory}>
                        <ListItemText primary="Stress Test" primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold' }} />
                    </ListItemButton>
                    <ListItemButton onClick={handleOpenAddUser}>
                        <ListItemText primary="Add User" primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold' }} />
                    </ListItemButton>
                    <ListItemButton onClick={handleOpenDeleteUser}>
                        <ListItemText primary="Delete User" primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold' }} />
                    </ListItemButton>
                </List>
                :
                <List>
                    <ListItem button component={Link} to="/" >
                        <ListItemText primary="Home" primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold' }} />
                    </ListItem>
                </List>
                }
            </Drawer>
        </Box>
    );
}